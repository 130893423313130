<template>
  <div>
    <div class="container" id="invoice">
      <div class="row mt-5">
        <div class="col-6">
          <p style="text-align: initial;">{{$t('account_statement_print.account_statement_for')}}:</p>
          <p style="text-align: initial;">{{data ? data.name : ''}}</p>
        </div>
        <div class="col-6">
          <h4 style="text-align: end;">{{company.business_name}}</h4>
          <p style="text-align: end;">{{company.address_1}}</p>
          <p style="text-align: end;">{{company.city ? company.city.name : ''}}, {{company.country ? company.country.name : ''}}</p>
        </div>
      </div>
      <div class="row mt-5">
        <table class="table table-bordered" v-if="basic_data">
          <thead>
          <tr style="background-color: #f8f9fa;">
            <th colspan="2">
              {{$t('account_statement_print.account_summary')}}
              <span v-if="account_statement.from_date"> {{ $t('account_statement_print.from') }} {{account_statement.from_date}} </span>
              <span v-if="account_statement.to_date"> {{ $t('account_statement_print.to') }} {{account_statement.to_date}} </span>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th width="70%">{{$t('account_statement_print.opening_balance')}}</th>
            <td width="30%">{{ basic_data.opening_balance }} {{ basic_data.currency }}</td>
          </tr>
          <tr v-for="(row, index) in custom_data_rows" :key="index">
            <th width="70%">{{$t('account_statement_print.balance')}} ({{ row.currency }})</th>
            <td width="30%">{{ row.opening_balance }} {{ row.currency }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.total')}}</th>
            <td width="30%">{{ basic_data.total }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.paid_to_date')}}</th>
            <td width="30%">{{ basic_data.total_payment }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.deserved_amount')}}</th>
            <td width="30%">{{ basic_data.deserved_amount }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.total_refund')}}</th>
            <td width="30%">{{ basic_data.total_refund }}</td>
          </tr>
          <tr>
            <th width="70%">{{$t('account_statement_print.total_expenses')}}</th>
            <td width="30%">{{ basic_data.total_expenses }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="row mt-5">
        <h4>{{$t('account_statement_print.account_transactions')}}</h4>
        <table class="table table-bordered">
          <thead>
          <tr style="background-color: #f8f9fa;">
            <th>{{$t('account_statement_print.the_date')}}</th>
            <th>{{$t('account_statement_print.operation_type')}}</th>
            <th>{{$t('account_statement_print.the_amount')}}</th>
            <th>{{$t('account_statement_print.the_currency')}}</th>
            <th>{{$t('account_statement_print.exchange_rate')}}</th>
            <th>{{$t('account_statement_print.balance_before')}}</th>
            <th>{{$t('account_statement_print.balance_after')}}</th>
            <th>{{$t('account_statement_print.amount_due_before')}}</th>
            <th>{{$t('account_statement_print.the_amount_due_after')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr  v-for="(row, index) in data.movements" :key="'movement'+index">
            <td>{{row.date}}</td>
            <td>{{row.type}}</td>
            <td>{{row.amount}}</td>
            <td>{{row.currency}}</td>
            <td>{{row.exchange_rate}}</td>
            <td>{{row.balance_before}}</td>
            <td>{{row.balance_after}}</td>
            <td>{{row.debit_before}}</td>
            <td>{{row.debit_after}}</td>
          </tr>
          </tbody>
          <tfoot>
          <!-- <tr>
            <th colspan="8">{{$t('account_statement_print.deserved_amount')}}</th>
            <td>{{data ? data.total_debit : '0'}}</td>
          </tr> -->
          </tfoot>
        </table>
      </div>

      <template v-if="related_customers_data && related_customers_data.length > 0 && account_statement.is_related_customers == 1">
<!--        <h4 class="text-center mb-6 mt-5">{{$t('account_statement_print.transactions_of_related_customers_accounts')}}</h4>-->
        <div class="row mt-5" v-for="(related_cu, index) in related_customers_data" :key="'related_cu'+index">
          <h4>{{related_cu.name}}</h4>
          <table class="table table-bordered">
            <thead>
            <tr style="background-color: #f8f9fa;">
              <th>{{$t('account_statement_print.the_date')}}</th>
              <th>{{$t('account_statement_print.operation_type')}}</th>
              <th>{{$t('account_statement_print.the_amount')}}</th>
              <th>{{$t('account_statement_print.the_currency')}}</th>
              <th>{{$t('account_statement_print.exchange_rate')}}</th>
              <th>{{$t('account_statement_print.balance_before')}}</th>
              <th>{{$t('account_statement_print.balance_after')}}</th>
              <th>{{$t('account_statement_print.amount_due_before')}}</th>
              <th>{{$t('account_statement_print.the_amount_due_after')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr  v-for="(row, index) in related_cu.movements" :key="'related_cu_movement'+index">
              <td>{{row.date}}</td>
              <td>{{row.type}}</td>
              <td>{{row.amount}}</td>
              <td>{{row.currency}}</td>
              <td>{{row.exchange_rate}}</td>
              <td>{{row.balance_before}}</td>
              <td>{{row.balance_after}}</td>
              <td>{{row.debit_before}}</td>
              <td>{{row.debit_after}}</td>
            </tr>
            </tbody>
            <tfoot>
            <!-- <tr>
              <th colspan="8">{{$t('account_statement_print.deserved_amount')}}</th>
              <td>{{data ? data.total_debit : '0'}}</td>
            </tr> -->
            </tfoot>
          </table>
        </div>

      </template>

    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import jsPdfExport from "@/core/config/jsPdfExport";
import timeZoneStructure from "@/core/config/mix/timeZoneStructure";

export default {
  name: "accountStatement-Customer",
  data(){
    return {
      mainRoute:'customers/customer/customer_statement',
      mainRouteForCompany:'settings/company/getCompany',
      data:{},
      related_customers_data:[],
      company:{},
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      account_statement:{
        from_date: null,
        to_date: null,
        is_related_customers: 0,
      },
      basic_data: [],
      custom_data_rows: [],

    }
  },
  methods: {
    ...timeZoneStructure,
    async getData() {
      await ApiService.query(`${this.mainRoute}/${this.id}`,this.account_statement).then((response) => {
        this.data = response.data.data;
        this.basic_data = response.data.data.basic_data[0];
        this.custom_data_rows = response.data.data.basic_data.filter((row, index)=>{
          if (index != 0)
            return row
        });
        this.related_customers_data = response.data.data.related_customers;
      });
    },
    async getCompany() {
      await ApiService.get(this.mainRouteForCompany).then((response) => {
        this.company = response.data.data ? response.data.data :  {};
      });
    },
    printInvoice() {
      // let tableId = 'invoice'
      // let divToPrint = document.getElementById(tableId);
      // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" +  divToPrint.outerHTML);
      // i18nService.getActiveLanguage();
      // setTimeout(() => {
      //   window.print();
      //   window.close();
      // }, 100)
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      let pdf = jsPdfExport("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");

      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
  },
  created() {
    this.account_statement.from_date = this.$route.query.from_date ? this.$route.query.from_date :  null;
    this.account_statement.to_date = this.$route.query.to_date;// ? this.$route.query.to_date :  new Date().toISOString().slice(0, 10);
    this.account_statement.is_related_customers = this.$route.query.is_related_customers ? this.$route.query.is_related_customers :  0;
    if(!this.account_statement.to_date){
      this.getTimeZone().then((res)=>{
        this.account_statement.to_date = res;
      });
    }
    let promise = this.getData();
    let promise2 = this.getCompany();
    Promise.all([promise, promise2]).then(()=>{
      this.show = true;
      this.printInvoice();
    });
  },
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
</style>